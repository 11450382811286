import React, { useState } from 'react';
import Particles from "react-tsparticles";
import './App.css';
import particlesOptions from "./particles.json";
import Header from './components/Header'
import Center from './components/Center'
import ContactForm from './components/ContactForm'
import ContactFormMobile from './components/ContactFormMobile'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from 'react-responsive'
import "pathseg"



const App = () => {
    const [contact, setContact] = useState(false)

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })


    const toastifySuccess = () => {
        toast.success('Thanks for reaching out!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: 'submit-feedback success',
        })
    }

    return (
        <div className="App">
            <div className="particles-container">
               <Particles options={particlesOptions}/>
            </div>
            <Header setContact={setContact} contact={contact}/>
            <span id="sub-header">Custom Website Creation and Design</span>
            {contact ? <div>{isMobile ? <ContactFormMobile setContact={setContact} toastifySuccess={toastifySuccess} /> : <ContactForm setContact={setContact} toastifySuccess={toastifySuccess}/>} </div> : <Center />}
            <ToastContainer />
        </div>
    );
}

export default App;
