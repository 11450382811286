import React from 'react'
import './CenterStyle.css'

const Center = () => {


  return (
    <div className="center-container">
      <em>
        Unique Websites for Innovative Companies
      </em>
    </div>
  )
}

export default Center
