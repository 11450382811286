import React, { useState } from 'react'
import './ContactForm.css'
import produce from "immer"
import emailjs from 'emailjs-com'


const ContactForm = (props) => {
  const { setContact, toastifySuccess } = props
  const [info, setInfo] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })
  const [badEmail, setBadEmail] = useState(false)

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



  const changeHandler = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    const nextState = produce(info, newInfo => {
      newInfo[name] = value
    })
    setInfo(nextState)
  }

  console.log('validation test > ', validateEmail('test@test.com') )
  console.log('bad email? ', badEmail)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (validateEmail(info.email)) {
      emailjs.send('service_fo7xgks', 'template_lcb86qe', info, 'user_Yo7Fz0QfyAh8bsJsx15M4')

      console.log(`
      --SUBMITTING--
      Name: ${info.name}
      Email: ${info.email}
      Subject: ${info.subject}
      Message: ${info.message}
    `);
      toastifySuccess()
      setContact(false)
    }
    else {
      setBadEmail(true)
    }
  }

  return (
    <div className="contact-form-container" onSubmit={handleSubmit} onChange={changeHandler}>
      <form>
      <span className="quote">
        Reach out for more info or to get a quote. <p />
      </span>
      <div className="top-line">
        <label>
          Name:
          <input type="text" name="name" />
        </label>
        {badEmail ? <label className="bad-email">
          Email:
          <input type="text" name="email" />
        </label> :  <label>
          Email:
          <input type="text" name="email" />
        </label>}
      </div>
      <div className="form-bottom" >
      <label>
        Subject:
        <input type="text" name="subject" />
      </label>
      <label id="message-input-label">
        Message:
        {/* <input type="textarea" name="message" id="message-input"/> */}
        <textarea name="message" id="message-input" />
      </label>
      </div>
      <div id="submit-container">
      <input type="submit" value="Submit" id="submit" />
      </div>
      </form>
    </div>
  )
}

export default ContactForm
