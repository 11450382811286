import React from 'react'
import './Header.css'
import Email from '../icons/Mail'


const Header = (props) => {
  const { contact, setContact} = props

  return (
    <div className='header-container'>
        <div className='logo' >
          SOPHONT TECHNOLOGIES
        </div>
        <div className='header-right' onClick={() => (setContact(contact ? false: true))}>
            <Email />
        </div>
    </div>
  )
}

export default Header

